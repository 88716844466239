import { type SanityImageFragment } from '@data/sanity/queries/types/image'
import { type SanityModule } from '@data/sanity/queries/types/modules'

import HeroLogo from '@components/hero-logo'
import Module, { type ModuleProps } from './module'

export type ModulesProps = Pick<
  ModuleProps,
  'pageProducts' | 'pageFeaturedProductIds'
> & {
  modules: SanityModule[] | null
  heroLogo?: SanityImageFragment
}

const Modules = ({
  modules,
  heroLogo,
  pageFeaturedProductIds,
  pageProducts,
}: ModulesProps) => {
  if (!modules) {
    return null
  }

  return (
    <>
      {modules.map((module, index) => {
        const child = (
          <Module
            key={module._key}
            module={module}
            pageFeaturedProductIds={pageFeaturedProductIds}
            pageProducts={pageProducts}
            // Include module type in class names for web vitals reporting
            className={`module:${module._type}`}
          />
        )

        if (heroLogo && index === 0) {
          return (
            <HeroLogo key={module._key} logo={heroLogo}>
              {child}
            </HeroLogo>
          )
        }

        return child
      })}
    </>
  )
}

export default Modules
